<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product/contest' }">赛事列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <div style="height: 20px;"></div>
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" ref="form" :model="form" :rules="form_rules"
                        :label-width="this.env.label_width">
                        <!-- 赛事名称 -->
                        <el-form-item label="赛事名称" prop="activity_title">
                            <el-input :disabled="!is_empty(activity_uuid)" v-model="form.activity_title" :maxlength="50" />
                        </el-form-item>
                        <el-form-item label="运动品类" v-if="is_empty(activity_uuid)">
                            <div class="category-select">
                                <el-select v-model="motion_tag" filterable placeholder="请选择运动品类" style="width: 100%">
                                </el-select>
                                <div class="category-select-child" @click="isMotionCategory = true"></div>
                            </div>
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="(item, index) in motion_category_two"
                                    class="form-tag_colour-tag" effect="dark" closable @close="motionClose(index)">
                                    <!-- ` item.pidName + '-' + item.name` -->
                                    {{ `${item.pidName ? item.pidName + '-' + item.name : item.name}` }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="运动品类" v-else>
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in form.motion_category_two"
                                    class="form-tag_colour-tag" effect="dark">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="运动效果" v-if="is_empty(activity_uuid)">
                            <div class="category-select">
                                <el-select v-model="motion_effect" filterable placeholder="请选择运动效果" style="width: 100%">
                                </el-select>
                                <div class="category-select-child" @click="isMotioneffect = true"></div>
                            </div>
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="(item, index) in target_category_zero"
                                    class="form-tag_colour-tag" effect="dark" closable @close="effectClose(index)">
                                    <!-- ` item.pidName + '-' + item.name` -->
                                    {{ `${item.pidName ? item.pidName + '-' + item.name : item.name}` }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="运动效果" v-else>
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in form.target_category_zero"
                                    class="form-tag_colour-tag" effect="dark">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <!-- 赛事类型 -->
                        <el-form-item label="赛事类型" prop="contest_type">
                            <el-radio-group :disabled="!is_empty(activity_uuid)" v-model="form.contest_type">
                                <el-radio :label="1">个人赛</el-radio>
                                <el-radio :label="2">团体赛</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- 人数限制 -->
                        <el-form-item v-if="form.contest_type === 1" label="人数限制" prop="quota">
                            <el-input-number :disabled="!is_empty(activity_uuid)" v-model="form.quota" :min="1" :max="999"
                                step-strictly></el-input-number>
                        </el-form-item>
                        <div v-else-if="form.contest_type === 2">
                            <el-form-item label="团队数限制" prop="quota">
                                <el-input-number :disabled="!is_empty(activity_uuid)" v-model="form.quota" :min="1"
                                    :max="999" step-strictly>
                                </el-input-number>
                            </el-form-item>
                            <el-form-item label="团队人数限制" prop="quota">
                                <el-radio-group style="margin-right:24px;" :disabled="!is_empty(activity_uuid)"
                                    v-model="form.contest_json.team_limit">
                                    <el-radio :label="0">否</el-radio>
                                    <el-radio :label="1">是</el-radio>
                                </el-radio-group>
                                <el-input-number v-if="form.contest_json.team_limit === 1"
                                    :disabled="!is_empty(activity_uuid)" v-model="form.contest_json.team_quota_limit"
                                    :min="1" :max="999" step-strictly>
                                </el-input-number>
                                <p style="color:#fc142f;margin: 0;">请选择是否限制参加团队赛的团体为固定人数，如不限制，则团队队长可以修改团队人数</p>
                            </el-form-item>
                        </div>
                        <!-- 赛事地址 -->
                        <div class="appoint">
                            <el-form-item label="活动地址" prop="county">
                                <div style="width:30%;display: inline-block;">
                                    <Region ref="region" v-model="form.region_code" :onlyallow="1"
                                        v-on:region="getregion" />
                                </div>
                                <div v-if="!this.Tool.is_empty(form.city)" style="width:70%;display: inline-block;">
                                    <el-autocomplete v-model="form.address" :fetch-suggestions="searchKeyAddress"
                                        placeholder="请输入内容" @select="selectAddress" @change="changeAddress($event, 'on')"
                                        style="width:100%">
                                        <template slot-scope="{ item }">
                                            <div style="white-space: normal;padding-top:8px ;padding-bottom: 8px;">
                                                <div style="font-size:14px;line-height: 21px;margin-bottom: 4px;">{{
                                                    item.name }}</div>
                                                <p style="margin:0;font-size: 12px;line-height: 18px; color: #999;">
                                                    {{
                                                        `${item.district}${item.address}` }}</p>
                                            </div>
                                        </template>
                                    </el-autocomplete>
                                </div>
                            </el-form-item>
                            <el-form-item label="地图">
                                <Amap ref="amap_edit" idindex="address" style="width:300px; height: 200px;" />
                            </el-form-item>
                        </div>
                        <!-- 联系方式 -->
                        <el-form-item label="联系方式" prop="activity_phone">
                            <el-input v-model="form.activity_phone" :maxlength="11"></el-input>
                        </el-form-item>
                        <!-- 关联门店 -->
                        <el-form-item label="指定门店" prop="activity_store">
                            <el-select v-model="form.store_s" multiple filterable placeholder="请选择门店" :loading="false"
                                :multiple-limit="1" style="width: 100%">
                                <el-option v-for="(item, key) in store_optionals" :key="key" :label="item.store_name"
                                    :value="item.store_uuid">
                                    <span style="color: #8492a6; font-size: 13px">
                                        【{{ item.company_name }}】
                                    </span>
                                    <span>{{ item.store_name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 主办方 -->
                        <el-form-item label="主办方" prop="sponsor_name">
                            <el-input v-model="form.sponsor_name" :maxlength="50" />
                        </el-form-item>
                        <!-- 承办方 -->
                        <el-form-item label="承办方" prop="organizer_name">
                            <el-input v-model="form.organizer_name" :maxlength="50" />
                        </el-form-item>
                        <!-- 报名时间 -->
                        <el-form-item label="报名时间" prop="signup_end_time">
                            <el-date-picker :disabled="!is_empty(activity_uuid)" v-model="signup_time" type="datetimerange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm">
                            </el-date-picker>
                        </el-form-item>
                        <!-- 赛事时间 -->
                        <el-form-item label="赛事时间">
                            <el-radio-group :disabled="!is_empty(activity_uuid)" v-model="activity_time_type">
                                <el-radio :label="1">固定时间</el-radio>
                                <el-radio :label="2">持续时间</el-radio>
                            </el-radio-group>
                            <div v-if="activity_time_type === 1">
                                <div v-for="(item, index) in this.form.detailed_time" :key="index">
                                    <el-col :span="2">
                                        <span style="width: 50px;">第{{ index + 1 }}天：</span>
                                    </el-col>
                                    <el-col :span="21">
                                        <el-date-picker :disabled="!is_empty(activity_uuid)" v-model="item.activity_date"
                                            type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                        <el-time-picker :disabled="item.activity_date === '' || !is_empty(activity_uuid)"
                                            is-range v-model="item.activity_time" range-separator="至"
                                            start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围"
                                            format="HH:mm" value-format="HH:mm">
                                        </el-time-picker>
                                        <el-link v-if="index > 0" type="danger" @click="del_activity_date(index)">删除
                                        </el-link>
                                    </el-col>
                                </div>
                                <el-button :disabled="!is_empty(activity_uuid)" type="primary" size="mini"
                                    icon="el-icon-plus" @click="add_activity_date">
                                </el-button>
                            </div>
                            <div v-else>
                                <el-date-picker :disabled="!is_empty(activity_uuid)" v-model="activity_time"
                                    type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                        <!-- 上传封面 -->
                        <el-form-item label="赛事封面" prop="cover">
                            <Qnupload v-model="form.cover" :isclipper="true" :compress="false" :fixedNumber="[4, 3]" />
                            <span class="form-tip">图片尺寸360*270 或 宽:高=4:3，用于赛事卡片封面展示。</span>
                        </el-form-item>
                        <!-- 赛事照片 -->
                        <el-form-item label="赛事照片" prop="photo">
                            <Qnupload v-model="form.photo" :sum="5" :isclipper="true" :compress="false"
                                :fixedNumber="[25, 28]" />
                            <span class="form-tip">图片尺寸 宽:高≈25:28，用于详情页轮播图展示。</span>
                        </el-form-item>
                        <!-- 报名费 -->
                        <el-form-item label="售价" prop="price">￥
                            <el-input-number v-model="form.price" :step="0.01" :disabled="!is_empty(activity_uuid)"
                                :min="0.00" :max="999999" step-strictly>
                            </el-input-number>
                        </el-form-item>
                        <!-- 退款规则 -->
                        <el-form-item label="退款规则" prop="is_refund">
                            <el-radio-group :disabled="!is_empty(activity_uuid)" v-model="form.is_refund">
                                <el-radio :label="0">不允许退款</el-radio>
                                <el-radio :label="1">允许</el-radio>
                            </el-radio-group>
                            <el-date-picker :disabled="form.is_refund === 0 || !is_empty(activity_uuid)"
                                v-model="form.is_refund_end_time" type="datetime" placeholder="选择日期&时间"
                                format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="margin-left: 10px;">
                            </el-date-picker>
                            <span style="margin-left: 10px;">前可退款</span>
                            <span
                                v-if="(!this.is_empty(this.form.detailed_time) && !this.is_empty(this.form.detailed_time[0].activity_date)) || (this.activity_time_type === 2 && !this.is_empty(this.activity_time))">
                                <span style="margin-left: 10px;">（赛事结束前</span>
                                <el-input-number :disabled="!is_empty(activity_uuid)" v-model="before_end_time" :step="0.5"
                                    :min="0" :max="24" size="mini" @change="before_end_time_js" step-strictly>
                                </el-input-number>
                                <span style="margin-left: 10px;">小时）</span>
                            </span>
                        </el-form-item>
                        <!-- 注意事项 -->
                        <el-form-item label="注意事项" prop="attention">
                            <el-input v-model="form.attention" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }"
                                maxlength="2000" show-word-limit></el-input>
                        </el-form-item>
                        <!-- 详情 -->
                        <el-form-item label="详情" prop="details_rich_text">
                            <!-- 富文本编辑框 -->
                            <Wangeditor ref="wangeditor_details_rich_text" v-model="form.details_rich_text"
                                idindex="details_rich_text" seewWidth="50%" />
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="is_auth('product.contest.issave')
                                && (time_decide_tool(form.signup_end_time, '>') || is_empty(activity_uuid))"
                                @click="save()" size="medium" type="primary">保存
                            </el-button>

                            <el-button size="medium" @click="isreturn">返回
                            </el-button>

                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
        <MotionCategory v-model="isMotionCategory" :motion_category_two="motion_category_two"
            @selectData="onSelectCategoryData" />
        <Motioneffect v-model="isMotioneffect" :target_category_zero="target_category_zero" @selectData="onMotioneffect" />
    </div>
</template>
<script>
import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'
import Region from '@/components/Region'
import Amap from '@/components/Amap'
import MotionCategory from '@/components/MotionCategory'
import Motioneffect from '@/components/Motioneffect'
export default {
    components: {
        Wangeditor,
        Qnupload,
        Region,
        Amap,
        MotionCategory,
        Motioneffect
    },
    data() {
        return {
            page_name: '赛事详情',
            activity_uuid: '',              // 赛事uuid
            store_optionals: [],            // 可选门店信息
            select_store_data: null,        // 选中的门店数据

            select_tag: '',
            tag_list: [],
            loading: false,

            signup_time: '',                // 报名时间
            activity_time: '',              // 赛事时间
            activity_time_type: 1,

            // 表单内容
            form: {
                activity_title: '',         // 赛事名称
                tag: [],
                province: '',
                city: '',
                county: '',
                region_code: '',
                sponsor_name: "", // 主办方
                organizer_name: "", // 承办方
                road: '',
                address: '',
                longitude: '',
                latitude: '',
                activity_phone: '',
                store_uuid_all: [],
                quota: 1,
                store_s: '',
                signup_start_time: 1,
                signup_end_time: 1,
                contest_type: 1,
                contest_json: {
                    team_limit: 0, // 是否限制团队数量 1 --> 是 0 --> 否
                    team_quota_limit: 0, // 团队人数限制
                }, // 团队人数限制
                // 赛事时间
                detailed_time: [
                    {
                        activity_date: '',
                        activity_time: ['00:00', '23:59'],
                    },
                ],
                video: '',
                photo: [],
                price: 0,
                is_refund: 0,
                is_refund_end_time: '',
                attention: '',
                details_rich_text: '',
                cover: "",
                motion_category_two: [],
                target_category_zero: [],
                province_code: 0, //省
                city_code: 0, // 市
                county_code: 0  // 区
            },
            // 表单内容验证规则
            form_rules: {
                activity_title: [
                    { required: true, message: '请输入赛事名称', trigger: 'blur' },
                    { min: 1, max: 50, message: '名称不能超过50个字', trigger: 'blur' }
                ],
                contest_type: [{ required: true, }],
                activity_store: [{ required: true, trigger: 'blur' }],
                details_rich_text: [{ required: true, }],
                tag: [{ required: true, }],
                county: [{ required: true, }],
                address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
                activity_phone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
                quota: [{ required: true, }],
                signup_end_time: [{ required: true, }],
                detailed_time: [{ required: true, }],
                photo: [{ required: true, }],
                cover: [{ required: true, }],
                price: [{ required: true, }],
                is_refund: [{ required: true, }],
                attention: [{ required: true, message: '请输入注意事项', }],
            },
            before_end_time: 0,             // 结束时间之前n小时
            isMotionCategory: false,
            motion_tag: '',
            motion_category_two: [],
            isMotioneffect: false,
            target_category_zero: [],
            motion_effect: ''
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
        this.gettag()
    },
    methods: {
        // 添加运动品类
        onSelectCategoryData(data) {
            this.motion_category_two = data;
            this.motion_tag = this.motion_category_two.length > 1 ? this.motion_category_two[0].name + ' ...' : this.motion_category_two[0].name
        },
        // 删除运动品类
        motionClose(index) {
            this.motion_category_two.splice(index, 1);
            this.motion_tag = this.motion_category_two.length > 1 ? this.motion_category_two[0].name + ' ...' : this.motion_category_two.length === 1 ? this.motion_category_two[0].name : ''
        },
        // 添加运动效果
        onMotioneffect(data) {
            this.target_category_zero = data;
            this.motion_effect = this.target_category_zero.length > 1 ? this.target_category_zero[0].name + ' ...' : this.target_category_zero[0].name
        },
        // 删除运动效果
        effectClose(index) {
            this.target_category_zero.splice(index, 1);
            this.motion_effect = this.target_category_zero.length > 1 ? this.target_category_zero[0].name + ' ...' : this.target_category_zero.length === 1 ? this.target_category_zero[0].name : ''
        },
        time_decide_tool(time, decide = '<', time2 = 'now') {
            if (typeof time === "string") {
                let date1 = new Date(time.replace(/-/g, "/")).getTime()        // 判断时间
                let date2 = new Date().getTime()            // 当前时间
                if (time2 !== 'now') {
                    date2 = new Date(time2.replace(/-/g, "/")).getTime()       // 判断时间2
                }
                if (decide === '>') {
                    if (date1 > date2) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    if (date1 < date2) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        },
        // 计算退款时间
        before_end_time_js() {
            let before_end_time_s = this.Tool.bcmul(this.before_end_time, 3600) * 1000
            let end_time, end_time_val
            if (this.activity_time_type === 1) {
                end_time = this.form.detailed_time[[this.form.detailed_time.length - 1]]
                end_time_val = end_time.activity_date + ' ' + end_time.activity_time[1]
            } else {
                end_time_val = this.activity_time[1]
            }
            end_time_val = new Date(end_time_val).getTime()
            if (this.is_empty(end_time_val)) {
                // this.before_end_time = 0
                this.Tool.errormes({ code: 10005, message: '请先选择赛事时间' })
                return false
            }

            let is_refund_end_time = this.Tool.bcsub(end_time_val, before_end_time_s)

            var time = new Date(is_refund_end_time);
            var y = time.getFullYear(); //getFullYear方法以四位数字返回年份
            var M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
            var d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
            var h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
            var m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
            let is_refund_end = y + '-' + M + '-' + d + ' ' + h + ':' + m

            this.form.is_refund = 1
            this.form.is_refund_end_time = is_refund_end
        },
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 增加赛事时间
        add_activity_date() {
            this.form.detailed_time.push({
                activity_date: '',
                activity_time: ['00:00', '23:59'],
            })
        },
        // 删除赛事时间
        del_activity_date(index) {
            this.form.detailed_time.splice(index, 1)
        },
        // 地址
        getregion(region_date) {
            this.form.province_code = region_date.province.region_code;
            this.form.city_code = region_date.city.region_code;
            this.form.county_code = region_date.county.region_code;
            this.form.province = region_date.province.region_level
            this.form.city = region_date.city.region_level
            this.form.county = region_date.county.region_level
        },
        searchKeyAddress(query, cb) {
            const { city_code } = this.form;
            this.$refs.amap_edit.autoComplete({
                city_code,
                query
            }, tips => {
                tips.forEach((item) => {
                    item.value = item.name;

                });
                tips.forEach((item, index) => {
                    if (!item.location) {
                        tips.splice(index, 1);
                    }

                });
                cb(tips)
            }, () => cb([]))
        },
        changeAddress(_event, type) {
            if (type === 'on') {
                const { address } = this.form;
                this.$refs.amap_edit.address2position({
                    city: this.form.city_code,
                    address
                }, geocodes => {
                    this.selectAddress(geocodes)
                }, () => {
                    this.$message({
                        message: '根据地址查询位置失败', type: 'error',
                        duration: this.env.message_error,
                    });
                })
            }

        },
        selectAddress(item) {
            const { location } = item;
            this.form.longitude = location.lng;
            this.form.latitude = location.lat;
            this.$refs.amap_edit.createMaker(item);
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.getStore()         // 获取可选门店
            let activity_uuid = this.$route.query.activity_uuid
            if (!this.Tool.is_empty(activity_uuid)) {
                this.activity_uuid = activity_uuid
                this.getinfo(activity_uuid)
            } else {
                this.loading = false
            }
        },
        //获取详情
        getinfo(activity_uuid) {
            let postdata = {
                api_name: "product.contest.getinfo",
                token: this.Tool.get_l_cache('token'),
                activity_uuid
            }
            // console.log('postdata', postdata)
            this.loading = true
            // let thi = this
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                // console.log('json', json)
                if (json.code === 0) {
                    if (!this.Tool.is_empty(json.data.store_uuid_all)) {
                        let store_s = [];
                        for (let v of json.data.store_uuid_all) {
                            store_s.push(v.store_uuid)
                        }
                        json.data.store_s = store_s
                    }
                    this.signup_time = [json.data.signup_start_time, json.data.signup_end_time]

                    if (json.data.time_type === 3) {
                        this.activity_time_type = 2
                        this.activity_time = [json.data.start_time, json.data.end_time]
                    }

                    this.form = json.data
                    this.$refs.wangeditor_details_rich_text.append(json.data.details_rich_text);    // 详情富文本
                    // 地址地图处理
                    this.$refs.region.init(json.data.region_code);
                    this.selectAddress({
                        location: {
                            lng: json.data.longitude,
                            lat: json.data.latitude,
                        },
                        name: json.data.address
                    })
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取标签
        gettag() {
            let postdata = {
                api_name: 'lesson.lesson.gettag',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tag_list = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加标签
        add_tag() {
            let select_tag = this.select_tag.split("|")
            let tag = {
                tag_name: select_tag[0],
                tag_colour: select_tag[1],
            }
            if (!this.tag_exist(tag.tag_name)) {
                this.form.tag.push(tag)
            }
            this.select_tag = ''
        },
        // 判断标签是否存在
        tag_exist(tag_name) {
            if (this.form.tag.length > 0) {
                for (let v of this.form.tag) {
                    if (tag_name == v.tag_name) {
                        return true
                    }
                }
                return false
            } else {
                return false
            }
        },
        // 删除标签
        tagClose(tag) {
            this.form.tag.splice(this.form.tag.indexOf(tag), 1);
        },
        // 选择门店
        selectStore(store_uuid) {
            for (let item of this.store_optionals) {
                if (item.store_uuid === store_uuid) {
                    this.select_store_data = item
                    break
                }
            }
        },
        // 获取可选门店
        getStore() {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getstore",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.store_optionals = json.data.list
                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }

            })
        },
        // 返回
        isreturn() {
            this.$router.push({ path: '/product/contest' })
        },
        // 提交表单
        save() {
            let currentMotionData = [...this.form.motion_category_two, ...this.motion_category_two];
            let currentTargetData = [...this.form.target_category_zero, ...this.target_category_zero];
            let postdata = {
                api_name: "product.contest.issave",
                token: this.Tool.get_l_cache('token'),
                // motion_category_two,
                // target_category_zero
            }
            // 处理所选门店
            this.form.store_uuid_all = []
            if (!this.is_empty(this.form.store_s)) {
                for (let v of this.form.store_s) {
                    for (let v2 of this.store_optionals) {
                        if (v == v2.store_uuid) {
                            this.form.store_uuid_all.push({
                                store_uuid: v2.store_uuid,
                                store_name: v2.store_name,
                            })
                        }
                    }
                }
            } else {
                this.Tool.errormes({ code: 10005, message: '请选择关联门店' });
                return false
            }

            // 处理报名时间
            if (this.Tool.is_empty(this.signup_time)) {
                this.Tool.errormes({ code: 10005, message: '请选择报名时间' })
                return false
            } else {
                this.form.signup_start_time = this.signup_time[0]
                this.form.signup_end_time = this.signup_time[1]
            }

            // 赛事名时间
            if (!this.Tool.is_empty(this.activity_time)) {
                this.form.activity_time = this.activity_time
            }

            if (this.activity_time_type === 1) {
                // 处理赛事时间
                for (let val of this.form.detailed_time) {
                    if (this.Tool.is_empty(val.activity_date)) {
                        this.Tool.errormes({ code: 10005, message: '请选择赛事日期' })
                        return false
                    }
                    if (this.Tool.is_empty(val.activity_time)) {
                        this.Tool.errormes({ code: 10005, message: '请选择赛事时间' })
                        return false
                    }
                }
            }
            // 退款时间判断
            if (this.form.is_refund === 2 && this.Tool.is_empty(this.form.is_refund_end_time)) {
                this.Tool.errormes({ code: 10005, message: '请选择报退款截止时间' })
                return false
            }
            Object.assign(postdata, this.form);
            let motionData = [], targetData = [];

            currentMotionData.map(element => motionData = motionData.concat(element.id));
            currentTargetData.map(element => targetData = targetData.concat(element.id))
            postdata['motion_category_two'] = motionData;
            postdata['target_category_zero'] = targetData;
            if (postdata.motion_category_two.length === 0) {
                this.$message.error('请选择运动品类');
                return
            }
            if (postdata.target_category_zero.length === 0) {
                this.$message.error('请选择运动效果');
                return
            }
            let reg = RegExp(
                /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
            );
            console.log(postdata)
            if (postdata["activity_title"].match(reg)) {
                this.$message.error('赛事名称不可包含特殊字符');
            } else {
                this.loading = true
                this.Tool.post_data('merchant', postdata, (json) => {
                    this.loading = false
                    if (json.code === 0) {
                        this.$message({
                            message: '操作成功', type: 'success',
                            duration: this.env.message_duration,
                            onClose: () => {
                                // 判断是否有id，没有返回列表（添加的情况）
                                if (this.Tool.is_empty(this.activity_uuid)) {
                                    this.isreturn();        // 返回到列表
                                }
                            }
                        });
                    } else {
                        this.loading = false;
                        this.Tool.errormes(json);
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.category-select-child {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 60%;
    cursor: pointer;
}
</style>